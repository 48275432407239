.button-radio {
  width: 100%;
  margin: 0.5rem 0 1.5rem 0;
}

.submit-button {
  width: 100%;
}

.link-search-point {
  font-size: 11px;
  text-decoration: underline;
  margin-top: -3px;
  margin-left: 24px;
  cursor: pointer;
  color: #98a6ad;
  position: absolute;
}

.scenario2-quote-view {
  h5 {
    font-weight: 600;
    margin-bottom: 2px;
  }
  h6 {
    color: $gray-600;
    font-weight: 500;
    margin: 2px 0;
  }
}
