.icon-map {
  color: #24b58d;
}

.filter-requests {
  margin-bottom: 20px;
}

.button-inline-requests {
  margin-top: 2em;
  width: 100%;
}

.dropdown-requests {
  float: right;
}

.timeline {
  position: relative;
}

.timeline li {
  list-style: none;
  float: left;
  width: 25%;
  position: relative;
  text-align: center;
}

.timeline-scenario3 li {
  width: 20%;
}

.no-carrier {
  width: 50% !important;
}

.timeline li div {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: block;
  text-align: center;
  line-height: 50px;
  margin: 0 auto 10px auto;
  background: #8e9ca8;
  color: #fff;
  transition: all ease-in-out 0.3s;
  z-index: 999;
}

.timeline li:after {
  content: '';
  position: absolute;
  width: calc(100% - 50px);
  margin-left: 25px;
  height: 1px;
  background-color: #8e9ca8;
  top: 25px;
  left: -50%;
  z-index: 1;
  transition: all ease-in-out 0.3s;
}

.timeline li span {
  font-size: 0.8rem;
}

.success-tracking {
  background: #03ad75 !important;
}

.alert-tracking {
  background: #e87960 !important;
}

.alert-tracking-msg {
  color: #e87960 !important;
}

.time-tracking {
  background: #727cf5 !important;
}

.timeline li:first-child:after {
  content: none;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
