//
// general.scss
//

html {
  position: relative;
  min-height: 100%;
}

body {
  overflow-x: hidden;
}

.btn-return {
  margin: 20px 0 20px 0;
}

.ft-size-1 {
  font-size: 1.25em;
}

.text-green {
  color: $color-green !important;
}

.mt-3-larger {
  margin-top: 1.7rem !important;
}

// Fix event bubbling on iOS devices
@supports (-webkit-overflow-scrolling: touch) {
  body {
    cursor: pointer;
  }
}
