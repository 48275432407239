.document-label-container {
  color: #000;
  height: 100vh;
  padding: 55px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 11px;
}

.document-label-container p {
  font-size: 9px;
}

.document-label-container tr {
  font-size: 11px;
}

.strong-sm {
  font-size: 8px !important;
}

.strong-lg {
  font-size: 12px !important;
}

.document-label-container table {
  color: #000;
}

.document-label-container tr {
  border-bottom: 1px solid #000;
}

.info-box {
  border: 1px solid #000;
}

.info-title {
  text-align: center;
}

.info-title h6 {
  font-size: 11px;
}

.info-title img {
  margin: 10px;
  width: 8em;
  height: auto;
}

.info-title-container {
  border-right: 1px solid;
}

.info-address {
  margin: 0 0 0 15px;
  font-size: 7px !important;
}

.info-address-operation {
  padding-left: 0;
  margin-top: 10px;
}

.info-days {
  margin: 5px 8px 0 8px;
}

.info-address-icon {
  margin-right: 5px;
}

.label-container {
  display: grid;
  grid-template-columns: 175px auto;
  margin-top: 20px;
}

.instructions-container {
  border: 1px solid #000;
  margin-right: 5px;
  width: 175px;
}

.instructions-title {
  margin: 5px;
  font-size: 0.8em;
}

.instructions-sub-title {
  font-size: 0.8em;
}

.instructions-title-bottom {
  border-bottom: 1px solid #000;
}

.instructions-steps-container {
  margin: 5px;
}

.label-content-container {
  border-width: 2px;
  border-style: dashed;
  border-color: #000;
  margin-left: 10px;
  position: relative;
}

.label-content-container-table {
  margin: 0px 12px 0 12px;
  line-height: 1.6em;
}

.label-content-container-table td {
  padding: 0.95rem 0.5rem;
}

.label-content-container-table p {
  margin: 0;
  padding: 0;
}

.label-content-container-table strong {
  margin: 0;
  padding: 0;
}

.td-center {
  text-align: center;
  vertical-align: middle;
}

.td-left {
  text-align: left;
}

.td-right-qr-code {
  text-align: right;
  vertical-align: middle;
  padding-right: 50px !important;
}

.td-tracking {
  margin-top: 0.7em;
  padding-top: 0.7em;
  margin-bottom: -0.7em;
  border-top: 1px solid #e8e7eb;
}

.tr-border {
  margin-top: 0.7em;
  padding-top: 0.7em;
  border-top: 1px solid #000;
}

.row-label {
  margin-top: 0.7em;
  margin-bottom: 0.7em;
}

.img-pdf {
  width: 1.2em;
  height: 1.2em;
  margin: 0.15em;
}

.img-pdf-scissors {
  position: absolute;
  top: -1.45em;
  left: 3em;
}

.td-img-logo-secondary {
  background-color: #d5d5d5;
  padding: 10px;
  height: 4.5em;
}

.td-img-logo-secondary img {
  width: 8em;
  height: auto;
  padding-top: 0.45em;
}

.td-Due-date {
  background-color: #eceef1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tipo-encomenda {
  display: flex;
  gap: 5px;
  align-items: center;
  align-content: center;
  font-size: 16px;
}

.div-entrega {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.texto-entrega {
  color: #629098;
  font-size: 12px;
  padding: 0;
  margin: 0;
}

.print-container-3 {
  display: flex;
  justify-content: space-between;
}

.transport-container {
  display: grid;
  grid-template-columns: 7.5em auto auto;
}

.rotulo-iata {
  display: flex;
  gap: 10px;
}

.rotulo-iata p {
  display: flex;
  align-items: center;
  gap: 10px;
}

.rotulo-iata p {
  height: 30px;
  padding: 10px;
  font-size: 24px;
  font-weight: bold;
  color: #ffffff;
  background-color: #000;
}

.label-mr-1 {
  margin-right: 1em;
}

.grid-2 {
  display: grid;
  grid-template-columns: 50% 50%;
  padding: 0 !important;
}
.grid-2 div {
  font-size: 7px !important;
  padding: 0 !important;
}

.grid-title {
  display: grid;
  grid-template-columns: 25% 42% 33%;
}

.grid-title div {
  padding: 0 7px 0 7px;
}

.img-carrier {
  width: 8em;
  height: auto;
}
.carrier-name {
  margin-left: 0.5em !important;
  font-size: 10px;
}

.bar-code-label {
  font-size: 10px;
  font-weight: 700;
}
